import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './about.css';
import img1 from '../../../assets/images/about/about1.svg';
import img2 from '../../../assets/images/about/about2.svg';
import person1 from '../../../assets/images/about/person1.svg';
import person2 from '../../../assets/images/about/person2.svg';
import person3 from '../../../assets/images/about/person3.svg';
import person4 from '../../../assets/images/about/person4.svg';
import person5 from '../../../assets/images/about/person5.svg';
import person6 from '../../../assets/images/about/gamze.svg';

import award1 from '../../../assets/images/award1.svg';
import award2 from '../../../assets/images/award2.svg';
import alper1 from '../../../assets/images/alper1.svg';
import alper2 from '../../../assets/images/alper2.svg';

function About() {
  return (
    <div className="mainPage">
      <Header />
      <div className="container-2">
        <div className="m-t-64 max-1040">
          <div className="about-t1">Biz Kimiz?</div>
          <div className="about-t2">{`${'Farklı bir zihniyetle alışılmadık ve beklenmedik olanı inşa ediyoruz! Ekibimiz dijital girişimcilik, finansal hizmetler sektörü, telekomünikasyon işi, teknoloji ve yönetim danışmanlığı, sistem entegrasyonu ve ürün yönetimi alanlarında geniş deneyime ve çeşitli becerilere sahip. Merak ve cesaretle "sonraki"ni tanımlamak ve inşa etmek için birbirimizi yükseltiyoruz. Sahadaki geniş deneyimimizle birçok yerel ve çok uluslu kuruluş için güvenilir iş ortağıyız. Açık bankacılık servislerini kullanarak bankaların ve finansal kuruluşların müşterilerine sunmuş olduğu ürünleri kolay, erişilebilir, yalın bir halde tek merkezden yönetme imkanı sağlıyoruz.'}`}</div>
        </div>
        <div className="about-cont">
          <img alt="img" src={img1} className="about-img1 m-r-8" />
          <img alt="img" src={img2} className="about-img1 m-l-8" />
        </div>
        <div className="about-cont1 m-t-64 d-none-small">
          <div className="about-cont2">
            <img alt="person" src={person1} />
            <div className="about-t3">Alper Akcan</div>
            <div className="about-t4">Co-Founder & CEO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person2} />
            <div className="about-t3">Tolga Ulutaş</div>
            <div className="about-t4">Co-Founder</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person3} />
            <div className="about-t3">Emre Hayretçi</div>
            <div className="about-t4">Co-Founder</div>
          </div>
        </div>
        <div className="about-cont1 m-t-32 d-none-small">
          <div className="about-cont2">
            <img alt="person" src={person4} />
            <div className="about-t3">Burak Karayel</div>
            <div className="about-t4">CTO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person5} />
            <div className="about-t3">Arzu Kahraman</div>
            <div className="about-t4">CPO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person6} />
            <div className="about-t3">Gamze Kepoğlu</div>
            <div className="about-t4">CCO</div>
          </div>
        </div>
        <div className="d-flex-small f-column m-t-80">
          <div className="about-cont2">
            <img alt="person" src={person1} className="about-cont2-img" />
            <div className="about-t3">Alper Akcan</div>
            <div className="about-t4">Co-Founder & CEO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person2} className="about-cont2-img" />
            <div className="about-t3">Tolga Ulutaş</div>
            <div className="about-t4">Co-Founder</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person3} className="about-cont2-img" />
            <div className="about-t3">Emre Hayretçi</div>
            <div className="about-t4">Co-Founder</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person4} className="about-cont2-img" />
            <div className="about-t3">Burak Karayel</div>
            <div className="about-t4">CTO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person5} className="about-cont2-img" />
            <div className="about-t3">Arzu Kahraman</div>
            <div className="about-t4">CPO</div>
          </div>
          <div className="about-cont2">
            <img alt="person" src={person6} className="about-cont2-img" />
            <div className="about-t3">Gamze Kepoğlu</div>
            <div className="about-t4">CCO</div>
          </div>
        </div>
        <div className="about-cont1 m-t-64 w100 f-column max-1040">
          <div className="about-t5">Ödüllerimiz</div>
          <div className="about-cont3 m-t-32">
            <div className="about-cont4">
              <img alt="award" src={alper1} className="about-img2" />
              <div className="d-flex f-column align-center m-t-64">
                <img alt="award" src={award1} />
                <div className="about-t4">
                  PSM Awards 2023
                  <div>
                    Yılın Startup’ı (Gümüş)
                  </div>
                </div>
              </div>
            </div>
            <div className="about-cont4-1">
              <img alt="award" src={alper2} className="about-img2" />
              <img alt="award" src={award2} className="m-t-40" />
              <div className="about-t4">
                Koçfinans
                <div>
                  Demo Day (Birincilik)
                </div>
              </div>
            </div>
            <div className="about-cont4 d-none-small" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
