import React from 'react';
import './loading.css';

function Loading() {
  return (
    <div className="loading-back">
      <div className="loading-loader" />
    </div>
  );
}

export default Loading;
