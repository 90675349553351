import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './contact.css';
import icon1 from '../../../assets/images/contact1.svg';
import icon2 from '../../../assets/images/contact2.svg';
import icon3 from '../../../assets/images/contact3.svg';

function Contact() {
  return (
    <div className="mainPage-2">
      <Header />
      <div className="container">
        <div className="contact-cont">
          <div className="contact-t1">Bize Ulaşın</div>
          <div className="contact-cont1">
            <div className="contact-cont2">
              <img alt="icon" src={icon1} />
              <div className="contact-t2">Şirket Bilgileri</div>
              <div className="contact-t3">Maslak Teknoloji A.Ş.</div>
              <div className="contact-t3">Beşiktaş V.D: 6131617600</div>
              <div className="contact-t3">Mersis: 0613161760000001</div>
            </div>
            <div className="contact-cont2">
              <img alt="icon" src={icon2} />
              <div className="contact-t2">Adres bilgileri</div>
              <div className="contact-t3">Kuruçeşme Mahallesi,</div>
              <div className="contact-t3">Arnavutköy Kuruçeşme Caddesi,</div>
              <div className="contact-t3">No:3/4</div>
              <div className="contact-t3">Beşiktaş, İstanbul</div>
            </div>
            <div className="contact-cont2">
              <img alt="icon" src={icon3} />
              <div className="contact-t2">İletişim bilgileri</div>
              <div className="contact-t3">info@fingate.io</div>
              <div className="contact-empty-div" />
              <div className="contact-t3">+90 212 942 50 60</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
