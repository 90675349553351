import React from 'react';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './reference.css';
import bank1 from '../../../assets/images/banks/bank1.svg';
import bank2 from '../../../assets/images/banks/bank2.svg';
import bank5 from '../../../assets/images/banks/bank5.svg';
import bank7 from '../../../assets/images/banks/bank7.svg';
import bank8 from '../../../assets/images/banks/bank8.svg';
import bank9 from '../../../assets/images/banks/bank9.svg';
import finans from '../../../assets/images/banks/finans.svg';
import garanti from '../../../assets/images/banks/garanti.svg';
import kuveyt from '../../../assets/images/banks/kuveyt.svg';
import odea from '../../../assets/images/banks/odea.svg';
import on from '../../../assets/images/banks/on.svg';
import qnb from '../../../assets/images/banks/qnb.svg';
import vakif from '../../../assets/images/banks/vakif.svg';
import partner1 from '../../../assets/images/partners/partner1.svg';
import partner3 from '../../../assets/images/partners/partner3.svg';
import partner4 from '../../../assets/images/partners/partner4.svg';
import partner8 from '../../../assets/images/partners/partner8.svg';
import payful from '../../../assets/images/partners/payful.svg';
import arcelik from '../../../assets/images/brands/arcelik.svg';
import decoverse from '../../../assets/images/brands/decoverse.svg';
import gizerler from '../../../assets/images/brands/gizerler.svg';
import gurgencler from '../../../assets/images/brands/gurgencler.svg';
import karaca from '../../../assets/images/brands/karaca.svg';
import lorenzo from '../../../assets/images/brands/lorenzo.svg';
import rebul from '../../../assets/images/brands/rebul.svg';
import saat from '../../../assets/images/brands/saat.svg';
import sigola from '../../../assets/images/brands/sigola.svg';
import sinerji from '../../../assets/images/brands/sinerji.svg';
import vivense from '../../../assets/images/brands/vivense.svg';
import zuhal from '../../../assets/images/brands/zuhal.svg';

function Reference() {
  return (
    <div className="mainPage">
      <Header />
      <div className="container">
        <div className="reference-cont00">
          <div className="reference-t1">Anlaşmalı Bankalarımız</div>
          <div className="m-t-16 d-none-small">
            <div className="reference-cont1">
              <img alt="bank" src={bank1} />
              <img alt="bank" src={bank2} />
              <img alt="bank" src={bank5} />
              <img alt="bank" src={garanti} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={kuveyt} />
              <img alt="bank" src={on} />
              <img alt="bank" src={odea} />
              <img alt="bank" src={qnb} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={bank8} />
              <img alt="bank" src={bank7} />
              <img alt="bank" src={finans} />
              <img alt="bank" src={vakif} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={bank9} />
            </div>
          </div>
          <div className="d-flex-small f-column m-t-24">
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank1} className="reference-img" />
              <img alt="bank" src={bank2} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank5} className="reference-img" />
              <img alt="bank" src={garanti} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={kuveyt} className="reference-img" />
              <img alt="bank" src={on} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={odea} className="reference-img" />
              <img alt="bank" src={qnb} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank8} className="reference-img" />
              <img alt="bank" src={bank7} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={finans} className="reference-img" />
              <img alt="bank" src={vakif} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={bank9} className="reference-img" />
              <div className="reference-img" />
            </div>
          </div>
        </div>
        <div className="reference-cont01">
          <div className="reference-t1">Anlaşmalı İş Ortaklarımız</div>
          <div className="m-t-16 d-none-small">
            <div className="reference-cont1">
              <img alt="bank" src={partner1} />
              <img alt="bank" src={partner3} />
              <img alt="bank" src={partner4} />
              <img alt="bank" src={partner8} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={payful} />
            </div>
          </div>
          <div className="d-flex-small f-column m-t-24">
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner1} className="reference-img" />
              <img alt="bank" src={partner3} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={partner4} className="reference-img" />
              <img alt="bank" src={partner8} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={payful} className="reference-img" />
            </div>
          </div>
        </div>
        <div className="reference-cont01">
          <div className="reference-t1">Anlaşmalı Markalarımız</div>
          <div className="m-t-16 d-none-small">
            <div className="reference-cont1">
              <img alt="bank" src={arcelik} />
              <img alt="bank" src={rebul} />
              <img alt="bank" src={decoverse} />
              <img alt="bank" src={gizerler} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={gurgencler} />
              <img alt="bank" src={karaca} />
              <img alt="bank" src={lorenzo} />
              <img alt="bank" src={saat} />
            </div>
            <div className="reference-cont1">
              <img alt="bank" src={sigola} />
              <img alt="bank" src={sinerji} />
              <img alt="bank" src={vivense} />
              <img alt="bank" src={zuhal} />
            </div>
          </div>
          <div className="d-flex-small f-column m-t-24">
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={arcelik} className="reference-img" />
              <img alt="bank" src={rebul} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={decoverse} className="reference-img" />
              <img alt="bank" src={gizerler} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={gurgencler} className="reference-img" />
              <img alt="bank" src={karaca} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={lorenzo} className="reference-img" />
              <img alt="bank" src={saat} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={sigola} className="reference-img" />
              <img alt="bank" src={sinerji} className="reference-img" />
            </div>
            <div className="d-flex justify-space-around w100">
              <img alt="bank" src={vivense} className="reference-img" />
              <img alt="bank" src={zuhal} className="reference-img" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Reference;
