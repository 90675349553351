import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../../views/header/header';
import Footer from '../../views/footer/footer';
import './demo.css';
import Loading from '../../views/loading/loading';

function Demo() {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [brandName, setBrandName] = useState('');
  const [brandWebSite, setBrandWebSite] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');

  const currentRef = useRef(true);
  useEffect(() => {
    if (currentRef.current) {
      initEmailSdk();
    }
    currentRef.current = false;
  }, []);
  function initEmailSdk() {
    emailjs.init({
      publicKey: 'rJFY9ZCLa-oYlPTCt',
    });
  }
  function sendEmail() {
    setLoading(true);
    const templateParams = {
      name,
      surname,
      brandName,
      brandWebSite,
      title,
      email,
      phone,
      mobilePhone,
      address,
      message,
    };

    emailjs.send('default_service', 'template_4ij3tic', templateParams).then(
      (response) => {
        emailjs.send('default_service', 'template_bce9ymq', templateParams).then(
          (response) => {
            setName('');
            setSurname('');
            setBrandName('');
            setBrandWebSite('');
            setTitle('');
            setEmail('');
            setPhone('');
            setMobilePhone('');
            setAddress('');
            setMessage('');
            setLoading(false);
          },
          (error) => {},
        );
      },
      (error) => {},
    );
  }
  return (
    <div className="mainPage">
      {loading && <Loading />}
      <Header />
      <div className="container-2">
        <div className="m-t-64 max-1040">
          <div className="demo-t1">Alışveriş Kredisi üye iş yeri olmak ister misiniz?</div>
          <div className="demo-t2">Alışveriş Kredisi için demo kullanım bilgilendirmesi veya bilgi almak için aşağıdaki formu doldurmanız yeterli. En kısa zamanda sizinle iletişime geçeceğiz.</div>
        </div>
        <div className="d-flex f-column w100 max-1040 m-t-24">
          <div className="demo-cnt1">
            <input
              placeholder="Adınız"
              className="inp m-r-16 m-t-24"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              placeholder="Soyadınız"
              className="inp m-t-24"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Markanızın adı"
              className="inp m-t-24"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Markanızın web sitesi"
              className="inp m-t-24"
              value={brandWebSite}
              onChange={(e) => setBrandWebSite(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Göreviniz"
              className="inp m-r-16 m-t-24"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              placeholder="E-posta adresiniz*"
              className="inp m-t-24"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Şirket telefonunuz"
              className="inp m-r-16 m-t-24"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              placeholder="Cep telefonunuz*"
              className="inp m-t-24"
              value={mobilePhone}
              onChange={(e) => setMobilePhone(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Şirket adresiniz"
              className="inp m-t-24"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="demo-cnt1">
            <input
              placeholder="Mesajınız"
              className="inp m-t-24"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="apply-demo" onClick={() => sendEmail()}>
            BAŞVUR
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Demo;
